/** @type {import('zudoku').ZudokuConfig} */
import { openApiPlugin } from "zudoku/plugins/openapi";


const url = globalThis?.location?.href;
let apiUrl = url ? new URL(url).searchParams.get("api-url") : "https://apidocs.indianic.biz/openapi.json";

const config = {
  plugins: [
    openApiPlugin({
      type: "url",
      input: apiUrl,
      navigationId: "/",
      skipPreload: false,
      inMemory: true,
    }),
  ],
  page: {
    pageTitle: "Rest API",
    logo: {
      src: {
        light: "./logo.jpeg",
        dark: "./logo.jpeg",
      },
    }
  },
  metadata: {
    title: "Indianic Infotech Ltd.",
    description: "This is a documentation for Indianic Dynamic Rest API",
    logo: "./public/logo.jpeg",
    favicon: "./public/favicon.ico",
    generator: "Website Generator 1.0",
    applicationName: "Indianic Dynamic Rest API",
    referrer: "no-referrer",
    keywords: ["restapi", "api", "indianic"],
    authors: ["Laukik Patel", "Rajen Trivedi"],
    creator: "Sandeep Mundra",
    publisher: "Indianic Infotech Ltd."
  },
};

export default config;
